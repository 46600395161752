import React, { useState } from 'react';
import axios from 'axios';
import './RespondeAi.css';

function RespondeAi() {
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [history, setHistory] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setHistory([...history, { question, answer }]);

    try {
      const response = await axios.post('https://generativelanguage.googleapis.com/v1beta/models/gemini-pro:generateContent?key=AIzaSyDhoeZlPhD3GrxyltEeeAoKB_422wCtuqY', {
        contents: [
          {
            parts: [
              {
                text: question
              }
            ]
          }
        ]
      });

      const data = response.data;
      const text = data?.candidates[0]?.content?.parts?.[0]?.text || '';
      setAnswer(text);
      setHistory([...history, { question: question, answer: text }]);
    } catch (error) {
      console.error('Erro ao enviar pergunta para a API Gemini:', error);
    }
    setQuestion('');
  };

  return (
  <div className="RespondeAi-container">
    <div className="RespondeAi-header">
      <h1>Histórico de Conversas:</h1>
    </div>
    <div className="RespondeAi-chat">
      {history.map((item, index) => (
        <div key={index} className="RespondeAi-message-container">
          {item.answer ? ( // Verifica se há uma resposta
            <div className="RespondeAi-message answer">
              <p>{item.answer}</p>
            </div>
          ) : null}
          <div className={`RespondeAi-message ${item.answer ? '' : 'question'}`}>
            <p>{item.question}</p>
          </div>
        </div>
      ))}
    </div>
    <form onSubmit={handleSubmit}>
      <div className="RespondeAi-input-container">
        <input
          type="text"
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
          placeholder="Digite sua pergunta..."
        />
        <button type="submit">Enviar</button>
      </div>
    </form>
  </div>
);


}

export default RespondeAi;
