import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Chamada from './components/Chamada';
import Footer from './components/Footer';
import BannerParceiro from './components/BannerParceiro';
import Manutencao from './components/Manutencao';
import RespondeAi from './components/RespondeAi';
import BannerVedo from './components/BannerVedo';
import { FaArrowUp, FaEnvelope, FaInfoCircle } from 'react-icons/fa';
import DuvidasFrequentes from './components/DuvidasFrequentes';
import Faleconosco from './components/Faleconosco';
import RotaPrivada from './RotaPrivada';
import Login from './components/Login';

import './App.css';

function App() {
  const [currentUser, setCurrentUser] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [activeDot, setActiveDot] = useState(0); // Estado para rastrear o índice do vídeo ativo

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  // Função para rolar suavemente para a seção de dúvidas
const scrollToDoubtsSection = () => {
  const doubtsSection = document.getElementById('duvidas-cvm-175');
  if (doubtsSection) {
    doubtsSection.scrollIntoView({ behavior: 'smooth' });
  }
};

  // Função para rolar suavemente para o topo da página
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  // Efeito para controlar a visibilidade do botão de rolagem ao rolar a página
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 2000) {
        // Defina a quantidade de rolagem necessária para exibir o botão
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (

    <Router>
    <main>
 <button onClick={scrollToDoubtsSection} className="scroll-to-doubts-btn">
    Tire suas dúvidas
  </button>



</main>

      <div className="App">
        <Chamada/>
        <Header menuOpen={menuOpen} toggleMenu={toggleMenu} />
        <Routes>
          <Route
            path="/"
            element={
              <div className="section">
                <main>
                    <DuvidasFrequentes/>
                     <div className="">

      <h4>Precisa de ajuda com algo específico?</h4>
      <div>
  <p>
    Se você não encontrou a resposta que procurava ou tem alguma outra dúvida, estamos aqui para ajudar. Entre em contato conosco!
  </p>
  <div className="link-container">
  <a href="#faleconosco" className="App-link">
    <span style={{ display: "inline-flex", alignItems: "center" }}>
      <FaEnvelope size={35} />
      <p style={{ marginLeft: "5px" }}>Contato</p>
    </span>
  </a>
  </div>
</div>
    </div>
                  <h6>PUBLICIDADE</h6>
                   <BannerParceiro />
                  <section id="nota" className="section">
                    <FaInfoCircle className="info-icon" size={40}/>
                    <p>Com o propósito de tornar as informações sobre a Resolução 175 mais acessíveis a nossos clientes, parceiros e demais interessados, criamos este espaço para consolidar em um único lugar as informações essenciais relacionadas a ela.</p>
                    <p>Nosso objetivo é facilitar o acesso direto ao texto oficial da Resolução, bem como manter os usuários informados sobre quaisquer alterações nos prazos. Além disso, disponibilizamos recursos adicionais, como vídeos explicativos, materiais educativos e links de notícias e fóruns de discussão voltados especificamente para elucidar
                    s e promover uma compreensão mais abrangente da Resolução.</p>
                    <p>Caso queira participar nos ajudando com materiais, apontando correções ou retirando dúvidas, não hesite em nos contatar. Estamos abertos a contribuições e prontos para auxiliá-los da melhor forma possível.</p>
                    <div className='link-container'>
                      <a href="#faleconosco" className="App-link">
                        <FaEnvelope size={35}/><p></p>
                      </a>
                    </div>
                  </section>

                  <section id="resolucao-cvm-175" className="section">
                    <h3>A Resolução</h3>
                    <p>Publicada pela Comissão de Valores Mobiliários (<strong>CVM</strong>) em dezembro de 2022, a <strong>Resolução 175</strong> representa a mais recente norma regulatória aplicada aos fundos de investimento e ao seu setor. Essa resolução vem para substituir a Instrução CVM 555 (ICVM 555), juntamente com outras 38 normas relacionadas.</p>
                    <p>A principal intenção da Resolução foi simplificar as regras, tornar o entendimento mais acessível, reduzir os custos, adequar as normas às novas realidades do mercado e aproximar o mercado nacional dos grandes mercados internacionais de fundos. Entre as mudanças implementadas, destacam-se a reformulação da estrutura dos fundos, que agora são apresentados por meio de três divisões: o fundo, suas classes e subclasses. Além disso, houve uma definição mais clara das responsabilidades dos prestadores de serviços, aumento da transparência em relação aos custos para os clientes e uma limitação da responsabilidade dos cotistas.</p>
                    <div className="link-container">
                    <p><a href="https://conteudo.cvm.gov.br/legislacao/resolucoes/resol175.html#:~:text=Disp%C3%B5e%20sobre%20a%20constitui%C3%A7%C3%A3o%2C%20o,revoga%20as%20normas%20que%20especifica.&text=ALTERADA%20pelas%20Resolu%C3%A7%C3%B5es%20181%2F23,%2F23%20e%20200%2F24." target="_blank" rel="noopener noreferrer" className="App-link">Resolução CVM 175 </a></p>
                    </div>
                  </section>

                  <section id="prazos-cvm-175" className="section">
                    <h3>Fique por dentro dos prazos</h3>
                    <p>Em reunião realizada em 6/3/2024, o Colegiado da Comissão de Valores Mobiliários (CVM) aprovou a Resolução CVM 200, <strong>que posterga prazos previstos na Resolução CVM 175</strong>.</p>
                    <p><a href="https://conteudo.cvm.gov.br/legislacao/resolucoes/resol200.html#:~:text=Disp%C3%B5e%20sobre%20a%20constitui%C3%A7%C3%A3o%2C%20o,revoga%20as%20normas%20que%20especifica.&text=ALTERADA%20pelas%20Resolu%C3%A7%C3%B5es%20181%2F23,%2F23%20e%20200%2F24." target="_blank" rel="noopener noreferrer" className="App-link"> Resolução CVM 200</a></p>
<p>Confira os novos prazos:</p>
                    <table>

                      <thead>
                        <tr>
                          <th>Adaptação à Resolução 175</th>
                          <th>Novo Prazo</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Estruturas de classes e subclasses de cotas</td>
                          <td>01 de out de 2024</td>
                        </tr>
                        <tr>
                          <td>Segregação das taxas dos fundos (taxa adm, taxa de gestão e taxa máxima de distribuição)</td>
                          <td>01 de nov de 2024</td>
                        </tr>
                        <tr>
                          <td>Estoque dos fundos de investimento em direitos creditórios – FIDC	</td>
                          <td>29 de nov de 2024</td>
                        </tr>
                        <tr>
                          <td>Estoque dos fundos de investimento em funcionamento (exceto FIDC)</td>
                        <td>30 de jun de 2025</td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                  <section id="quemsomos" className="section">
                    <h3>Quem Somos</h3>
                    <p>Somos uma empresa especializada em consultoria de tecnologia da informação, com um foco destacado na entrega de soluções adaptadas especificamente para atender às necessidades do mercado financeiro, com especial atenção às atividades vinculadas à CVM e ANBIMA.</p>
                    <p>Nossos sócios combinam uma profunda compreensão do mundo da tecnologia com um conhecimento sólido das particularidades do mercado financeiro. Com uma abordagem abrangente, oferecemos uma variedade de serviços, incluindo automação de rotinas, otimização de processos, análise de dados e implementação de soluções em nuvem.</p>
                    <Faleconosco />
                  </section>

                </main>
              </div>
            }
          />

          <Route path="/manutencao" element={currentUser ? <Manutencao /> : <Navigate to="/login" />} />
          <Route path="/login" element={<Login />} />

           <Route  path="/respondeai"  element={currentUser ? <RespondeAi /> : <Navigate to="/respondeai" />}/>        </Routes>

        <h6>PUBLICIDADE</h6>
        <BannerVedo />
</div>
        <Footer />
        {/* Botão para rolar para o topo */}
        {showScrollButton && (
         <button id="scrollToTopBtn" onClick={scrollToTop}>
           <FaArrowUp size="25"/>
        </button>
        )}

    </Router>

  );
}

export default App;
