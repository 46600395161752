import React from 'react';

import './Footer.css';

function Footer() {
  return (
    <footer className="Footer">
      <div className="footer-content">
        <div className="footer-left">
          <h4>Links úteis</h4>
          <ul>
            <li><a href="https://www.anbima.com.br/pt_br/index.htm" target="_blank" rel="noopener noreferrer">ANBIMA</a></li>
            <li><a href="https://www.gov.br/cvm/pt-br" target="_blank" rel="noopener noreferrer">CVM</a></li>
            <li><a href="https://www.bcb.gov.br/" target="_blank" rel="noopener noreferrer">Banco Central do Brasil</a></li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="linha-gradiente"></div> {/* Linha gradiente */}
        <p> &reg; Consultoria</p>
      </div>
    </footer>
  );
}

export default Footer;
