import React, { useState, useEffect } from 'react';

function BannerParceiro() {
  const [imagemAleatoria, setImagemAleatoria] = useState('');
  const imagens = ['/hbescola.png', '/hbcursos.png'];

  useEffect(() => {
    const intervalId = setInterval(() => {
      const indiceAleatorio = Math.floor(Math.random() * imagens.length);
      setImagemAleatoria(imagens[indiceAleatorio]);
    }, 3000);

    return () => clearInterval(intervalId); // Limpa o intervalo quando o componente é desmontado
  }, [imagens]);

  const selecionarImagemAleatoria = () => {
    const indiceAleatorio = Math.floor(Math.random() * imagens.length);
    return imagens[indiceAleatorio];
  };

  return (
    <div className="banner-parceiro">
      <a
        href="https://hbescoladenegocios.engaged.com.br/todos"
        target="_blank"
        rel="noopener noreferrer"
        className="Logo-link"
        alt="HB Escola de Negócios"
        title="HB Escola de Negócios"
      >
        <img
          src={imagemAleatoria || selecionarImagemAleatoria()}
          alt="Banner Parceiro"
          width="80%"
        />
      </a>
    </div>
  );
}

export default BannerParceiro;
