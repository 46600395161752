import React, { useState } from 'react';
import { FaBook, FaHome, FaTimes } from "react-icons/fa";
import { Link } from 'react-router-dom'; // Importe Link do React Router
import './Header.css';

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
    setMenuOpen(false); // Fechar o menu após clicar no link
  };

  return (
    <header className={`Header ${menuOpen ? 'menu-open' : ''}`}>
      <nav className="Nav">
        <div className="menu-container">
          <Logo />
          <Menu menuOpen={menuOpen} toggleMenu={toggleMenu} scrollToTop={scrollToTop} />
        </div>
      </nav>
    </header>
  );
}

function Logo() {
  return (
    <div className="logo-container">
      <div className="Logo left-logo">
        <Link to="/" className="Logo">
          <img src="/logo.png" alt="Logo FCBO Consultoria" className="logo-img" />
        </Link>
      </div>
    </div>
  );
}

function Menu({ menuOpen, toggleMenu, scrollToTop }) {
  return (
    <>
      <div className="menu-toggle" onClick={toggleMenu}>
        {menuOpen ? <FaTimes size={30} style={{ background: 'transparent', padding: 0 }} /> : <div className="bar"></div>} {/* Alterne entre os ícones do menu e "X" */}
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
      <ul className={`Nav-list ${menuOpen ? 'open' : ''}`}>
        <li className="Nav-item"><Link to="/" onClick={scrollToTop}><FaHome size={30} / ></Link></li>
        <li className="Nav-item"><a href="/" onClick={toggleMenu}>Início</a></li>
        <li className="Nav-item"><a href="#resolucao-cvm-175" onClick={toggleMenu}>Resolução</a></li>
        <li className="Nav-item"><a href="#prazos-cvm-175" onClick={toggleMenu}>Prazos</a></li>
        <li className="Nav-item"><a href="#duvidas-cvm-175" onClick={toggleMenu}>Dúvidas</a></li>
        <li className="Nav-item"><a href="#quemsomos" onClick={toggleMenu}> Quem Somos</a></li>

      </ul>
    </>
  );
}

export default Header;
