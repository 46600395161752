import React, { useState } from 'react';
import './Faleconosco.css';
import emailjs from 'emailjs-com';


function QuemSomos() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [sending, setSending] = useState(false);

  const enviarEmail = (e) => {
    e.preventDefault();
    setSending(true); // Desativa o botão enquanto o email está sendo enviado

    emailjs.sendForm('enviaremailcvm', 'template_venaxcj', e.target, 'CtqNjPWscFl08NDjp')
      .then(
        () => {
          console.log('SUCCESS!');
          setSuccessMessage('E-mail enviado com sucesso!');
          setName('');
          setEmail('');
          setMessage('');
        },
        (error) => {
          console.log('FAILED...', error.text);
          setError('Erro ao enviar e-mail. Por favor, tente novamente.');
        }
      )
      .finally(() => {
        setSending(false); // Reativa o botão após o email ser enviado
      });
  };

  return (
    <div id="faleconosco" className="Quemsomos">
      <h3>Como podemos ajudar?</h3>
      <form onSubmit={enviarEmail}>
        <label>
          <input
            type="text"
            name="name"
            placeholder="Digite seu nome*"
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </label>
        <label>
          <input
            type="email"
            name="email"
            placeholder="Email*"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </label>
        <label>
          <textarea
            name="message"
            placeholder="Mensagem*"
            required
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
        </label>
        <button className="button-form" type="submit" disabled={sending}>Enviar</button>
      </form>
      {error && <p>{error}</p>}
      {successMessage && <p>{successMessage}</p>}

    </div>

  );
}

export default QuemSomos;
