import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import './Manutencao.css';
import { SiGoogleanalytics } from "react-icons/si";
import RespondeAi from './RespondeAi';

const Manutencao = ({ onPerguntaAdicionada }) => {
  const [user, setUser] = useState(null);
  const [pergunta, setPergunta] = useState('');
  const [videoId, setVideoId] = useState('');
  const [perfil, setPerfil] = useState('');
  const [acessos, setAcessos] = useState(0);
  const [ordem, setOrdem] = useState(0);
  const [registros, setRegistros] = useState([]);
  const [confirmacaoExclusao, setConfirmacaoExclusao] = useState(null);
  const [edicaoRegistro, setEdicaoRegistro] = useState(null);
  const [pesquisa, setPesquisa] = useState('');
  const [mensagem, setMensagem] = useState({ tipo: '', texto: '' });

  // Estados para valores de edição
  const [perguntaEdicao, setPerguntaEdicao] = useState('');
  const [videoIdEdicao, setVideoIdEdicao] = useState('');
  const [perfilEdicao, setPerfilEdicao] = useState('');
  const [acessosEdicao, setAcessosEdicao] = useState(0);
  const [ordemEdicao, setOrdemEdicao] = useState(0);

  useEffect(() => {
    // Adicione um observador de autenticação do Firebase
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // Se houver um usuário autenticado, atualize o estado do usuário
        setUser(user);
      } else {
        // Se não houver um usuário autenticado, defina o estado do usuário como null
        setUser(null);
      }
    });

    return () => {
      // Remova o observador ao desmontar o componente
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    const fetchRegistros = async () => {
      try {
        const db = firebase.firestore();
        const registrosSnapshot = await db.collection('perguntas').get();
        const registrosData = registrosSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setRegistros(registrosData);
      } catch (error) {
        console.error('Erro ao buscar registros:', error);
      }
    };

    fetchRegistros(); // Chame a função fetchRegistros após definir o useEffect

  }, []); // Certifique-se de passar uma array vazia como segundo argumento para garantir que o useEffect seja executado apenas uma vez

  const limparCampos = () => {
    setPergunta('');
    setVideoId('');
    setPerfil('');
    setAcessos(0);
    setOrdem(0);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Verifique se há um usuário autenticado
    if (!user) {
      // Se não houver usuário autenticado, exiba uma mensagem de erro ou redirecione para a página de login
      console.error('Usuário não autenticado. Acesso negado.');
      return;
    }

    try {
      const db = firebase.firestore();

      // Verifica se está em modo de edição ou adição
      if (edicaoRegistro) {
        await handleAtualizarPergunta(edicaoRegistro, perguntaEdicao, videoIdEdicao, perfilEdicao, acessosEdicao,ordemEdicao);
      } else {
        // Verifica se a pergunta já existe
        const perguntasRef = await db.collection('perguntas').where('pergunta', '==', pergunta.toLowerCase()).get();
        if (!perguntasRef.empty) {
          setMensagem({ tipo: 'erro', texto: 'Pergunta já cadastrada!' });
          return;
        }

        // Adiciona nova pergunta
        await db.collection('perguntas').add({ pergunta, videoId, perfil, acessos, ordem, datareg: firebase.firestore.FieldValue.serverTimestamp() });

        const novaPergunta = { pergunta, videoId, perfil, acessos, ordem };



        // Verifica se onPerguntaAdicionada é uma função antes de chamá-la
        if (typeof onPerguntaAdicionada === 'function') {
          onPerguntaAdicionada(novaPergunta);
        } else {
          console.error('onPerguntaAdicionada não é uma função válida.');
        }
      }

      // Limpa os campos e exibe mensagem de sucesso
      setMensagem({ tipo: 'sucesso', texto: 'Cadastro realizado com sucesso!' });
      setTimeout(() => {
        setMensagem({ tipo: '', texto: '' });
        limparCampos();
      }, 3000);
    } catch (error) {
      console.error('Erro ao adicionar/atualizar pergunta:', error);
      setMensagem({ tipo: 'erro', texto: 'Erro ao adicionar/atualizar pergunta. Por favor, tente novamente mais tarde.' });
    }
  };


  const handleExcluirPergunta = async (id) => {
    if (confirmacaoExclusao !== id) {
      setConfirmacaoExclusao(id);
      return;
    }

    try {
      const db = firebase.firestore();
      await db.collection('perguntas').doc(id).delete();
      setRegistros(registros.filter(registro => registro.id !== id));
      setConfirmacaoExclusao(null);
    } catch (error) {
      console.error('Erro ao excluir pergunta:', error);
      setMensagem({ tipo: 'erro', texto: 'Erro ao excluir pergunta. Por favor, tente novamente mais tarde.' });
    }
  };

  const handleEditarPergunta = (id) => {
    const registroParaEditar = registros.find(registro => registro.id === id);
    if (registroParaEditar) {
      setPerguntaEdicao(registroParaEditar.pergunta);
      setVideoIdEdicao(registroParaEditar.videoId);
      setPerfilEdicao(registroParaEditar.perfil);
      setAcessosEdicao(registroParaEditar.acessos);
      setOrdemEdicao(registroParaEditar.ordem);
      setEdicaoRegistro(id);
    } else {
      console.error('Registro não encontrado para edição.');
    }
  };

  const handleAtualizarPergunta = async (id, perguntaAtualizada, videoIdAtualizado, perfilAtualizado, acessosAtualizados, ordemAtualizada) => {
  try {
    const db = firebase.firestore();

    // Busca o registro atual no Firestore para obter o valor atual de acessos
    const registroAtual = await db.collection('perguntas').doc(id).get();
    const acessosAntigos = registroAtual.data().acessos;

    // Calcula o novo valor de acessos com base no valor atualizado em acessosEdicao
    const acessosNovos = isNaN(parseInt(acessosAtualizados)) ? acessosAntigos : parseInt(acessosAtualizados);

    // Atualiza o documento Firestore com os novos dados
    await db.collection('perguntas').doc(id).update({ pergunta: perguntaAtualizada, videoId: videoIdAtualizado, perfil: perfilAtualizado, acessos: acessosNovos, ordem: ordemAtualizada });

    // Atualiza o estado local apenas se a operação no Firestore for bem-sucedida
    const registrosAtualizados = registros.map(registro =>
      registro.id === id ? { ...registro, pergunta: perguntaAtualizada, videoId: videoIdAtualizado, perfil: perfilAtualizado, acessos: acessosNovos, ordem: ordemAtualizada } : registro
    );
    setRegistros(registrosAtualizados);
    setEdicaoRegistro(null);
  } catch (error) {
    console.error('Erro ao atualizar pergunta:', error);
    setMensagem({ tipo: 'erro', texto: 'Erro ao atualizar pergunta. Por favor, tente novamente mais tarde.' });
  }
};

  const filtrarRegistros = () => {
    return registros.filter(registro => registro.pergunta.toLowerCase().includes(pesquisa.toLowerCase()));
  };

  return (
    <div className="manutencao-container">
    <RespondeAi />
    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
  <SiGoogleanalytics />
  <p style={{ marginLeft: '10px' }}>
    <a href="https://analytics.google.com/analytics/web/#/p431891527/reports/reportinghub?params=_u..nav%3Dmaui" target="_blank" rel="noopener noreferrer" className="App-link">Google Analytics</a>
  </p>
</div>
      <div className="header-container">
        <h1>MANUTENÇÃO</h1>
        <h2>Cadastrar</h2>
      </div>

      {mensagem.tipo && (
        <div className={mensagem.tipo === 'sucesso' ? 'success-message' : 'error-message'}>
          {mensagem.texto}
        </div>
      )}

  <form onSubmit={handleSubmit}>
  <label>
    Pergunta:
    <input className="input-field" type="text" value={pergunta} onChange={(e) => setPergunta(e.target.value)} required />
  </label>
  <label>
    VideoID:
    <input className="input-field" type="text" value={videoId} onChange={(e) => setVideoId(e.target.value)} required />
  </label>
  <label>

    <select className="input-field small-select" value={perfil} onChange={(e) => setPerfil(e.target.value)} required>
      <option value="">Selecione o perfil</option>
      <option value="Investidores">Investidores</option>
      <option value="Administradores e Gestores">Administradores e Gestores</option>
      <option value="Distribuidores">Distribuidores</option>
    </select>
  </label>
  <label>
    Ordem:
    <input className="input-field" type="text" value={ordem} onChange={(e) => setOrdem(e.target.value)} />
  </label>
  <button className="submit-button" type="submit">Adicionar Pergunta</button>
</form>

      <div className="search-container">
        <h2>PESQUISAR</h2>
        <input className="search-input" type="text" value={pesquisa} onChange={(e) => setPesquisa(e.target.value)} placeholder="Pesquisar por termo" />
      </div>

      {registros.length > 0 && (
        <div className="records-container">
          <h2>Perguntas Publicadas</h2>
          <ul className="records-list">
            {filtrarRegistros().map(registro => (
              registro.pergunta && (
                <li key={registro.id} className="record-item">
                  <span>{registro.pergunta}</span>
                  <div className="buttons-container">
                    {edicaoRegistro === registro.id ? (
  <form onSubmit={(e) => {
    e.preventDefault();
    handleAtualizarPergunta(registro.id, perguntaEdicao, videoIdEdicao, perfilEdicao, acessosEdicao, ordemEdicao);
  }}>
    <label>
      Pergunta:
      <input className="input-field" type="text" value={perguntaEdicao} onChange={(e) => setPerguntaEdicao(e.target.value)} required />
    </label>
    <label>
      VideoID:
      <input className="input-field" type="text" value={videoIdEdicao} onChange={(e) => setVideoIdEdicao(e.target.value)} required />
    </label>
    <label>
      <select className="input-field small-select" value={perfilEdicao} onChange={(e) => setPerfilEdicao(e.target.value)} required>
        <option value="">Selecione o perfil</option>
        <option value="Investidores">Investidores</option>
        <option value="Administradores e Gestores">Administradores e Gestores</option>
        <option value="Distribuidores">Distribuidores</option>
      </select>
    </label>
    <label>
      Acessos:
      <input className="input-field" type="text" value={acessosEdicao} onChange={(e) => setAcessosEdicao(e.target.value)}  />
    </label>
    <label>
      Ordem:
      <input className="input-field" type="text" value={ordemEdicao} onChange={(e) => setOrdemEdicao(e.target.value)}  />
    </label>
    <div className="buttons-container">
      <button className="update-button" type="submit">Atualizar</button>
      <button className="cancel-button" onClick={() => {
        setEdicaoRegistro(null);
        setPerguntaEdicao('');
        setVideoIdEdicao('');
        setPerfilEdicao('');
        setAcessosEdicao(0);
        setOrdemEdicao(0);
      }}>Cancelar</button>
    </div>
  </form>
) : (
  <>
    <button className="delete-button" onClick={() => handleExcluirPergunta(registro.id)}>
      {confirmacaoExclusao === registro.id ? 'Confirmar Exclusão' : 'Excluir'}
    </button>
    <button className="edit-button" onClick={() => handleEditarPergunta(registro.id)}>Editar</button>
  </>
)}
                  </div>
                </li>
              )
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Manutencao;