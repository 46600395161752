import React, { useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import { Navigate } from 'react-router-dom';
import './Login.css'; // Importe o arquivo CSS
import Manutencao from './Manutencao';


const firebaseConfig = {
  // suas configurações do Firebase aqui
};

// Inicialize o Firebase se ainda não estiver inicializado
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const Login = () => {
  const [loginMessage, setLoginMessage] = useState(''); // Estado para armazenar a mensagem de login
  const [loggedIn, setLoggedIn] = useState(false); // Estado para controlar se o usuário está logado

  const handleGoogleLogin = async () => {
    try {
      const provider = new firebase.auth.GoogleAuthProvider();
      const result = await firebase.auth().signInWithPopup(provider);

      // Verifique se o e-mail do usuário está autorizado
      const userEmail = result.user.email;
      const authorizedEmails = ['umdevbr@gmail.com', 'fndo.xyz@gmail.com','fcbertoo@gmail.com' ]; // Lista de e-mails autorizados
      if (authorizedEmails.includes(userEmail)) {
        // Se o e-mail do usuário estiver autorizado, marque o usuário como logado
        setLoggedIn(true);
        setLoginMessage('Login bem-sucedido!');
      } else {
        // Se o e-mail do usuário não estiver autorizado, faça logout e exiba uma mensagem de erro
        await firebase.auth().signOut();
        setLoginMessage('Acesso não autorizado. Entre em contato com o administrador.');
      }
    } catch (error) {
      console.error('Error signing in with Google:', error.message);
      // Se houver um erro no login, atualize a mensagem de login com o erro
      setLoginMessage(`Erro ao fazer login: ${error.message}`);
    }
  };

  // Se o usuário estiver logado, redirecione para a rota de manutenção
  if (loggedIn) {
    return <Manutencao />;
  }

  return (
    <div className="container">
      <h2 className="title">Entrar com o Google</h2>
      <button className="button" onClick={handleGoogleLogin}>Login with Google</button>
      {loginMessage && <p className="login-message">{loginMessage}</p>} {/* Exibir a mensagem de login se existir */}
    </div>
  );
};
export default Login;
