import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import './DuvidasFrequentes.css';
import { FaLinkedin, FaWhatsapp, FaWindowClose } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import BannerParceiro from './BannerParceiro';


const firebaseConfig = {
  apiKey: "AIzaSyDdWWygVWSEr8f1XpNXEFrfwBA9pcbf7pg",
  authDomain: "cvm175-bf82a.firebaseapp.com",
  projectId: "cvm175-bf82a",
  storageBucket: "cvm175-bf82a.appspot.com",
  messagingSenderId: "973742267253",
  appId: "1:973742267253:web:b7ffaf4282d1b42184d13c",
  measurementId: "G-23HSNNCKRT"
};

    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
    }

    const DuvidasFrequentes = () => {
      const [records, setRecords] = useState([]);
      const [videoExibido, setVideoExibido] = useState(null);
      const [activeAccordion, setActiveAccordion] = useState(null);
      const [barColor, setBarColor] = useState('#007bff');

      useEffect(() => {
        const selectedButton = document.querySelector('.button.selected');
        if (selectedButton) {
          const selectedButtonColor = window.getComputedStyle(selectedButton).getPropertyValue('background-color');
          setBarColor(selectedButtonColor);
        }
      }, [activeAccordion]);

      useEffect(() => {
        const fetchRecords = async () => {
          try {
            const db = firebase.firestore();

            let recordsRef = db.collection('perguntas').where('perfil', '==', activeAccordion);
            const recordsSnapshot = await recordsRef.get();
            const recordsData = recordsSnapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data()
            }));
            setRecords(recordsData);

          } catch (error) {
            console.error('Erro ao buscar registros:', error);
          }
        };
        fetchRecords();
      }, [activeAccordion]);

      const handleAccordion = (perfilSelecionado) => {
        setActiveAccordion(activeAccordion === perfilSelecionado ? null : perfilSelecionado);
      };

      const incrementarAcesso = async (videoId) => {
        try {
          const videoRef = firebase.firestore().collection('perguntas').doc(videoId);
          await videoRef.update({
            acessos: firebase.firestore.FieldValue.increment(1)
          });
        } catch (error) {
          console.error('Erro ao atualizar contagem de acesso:', error);
        }
      };

      const alternarVideo = (videoId) => {
        if (videoId === 'canaldeajuda') {
          setVideoExibido('XpcJJNIa6YA?si=bDDi85zg-LWV0lZh');
          incrementarAcesso(videoId);
        } else {
          const videoSelecionado = records.find(record => record.id === videoId);
          if (videoSelecionado && videoSelecionado.videoId) {
            setVideoExibido(videoSelecionado.videoId);
            incrementarAcesso(videoId);
          } else {
            console.error(`Vídeo com ID ${videoId} não encontrado.`);
          }
        }
      };

      const renderizarPerguntasMaisFrequentes = () => {
        const perguntasComAcesso = records
          .filter(record => record.acessos)
          .sort((a, b) => b.acessos - a.acessos)
          .slice(0, 3);

        return (
          <div className="cards-column">
            <h4>Mais frequentes</h4>
            {perguntasComAcesso.map(pergunta => (
              <p key={pergunta.id}>
                <a
                  href={`#${encodeURIComponent(pergunta.pergunta ? pergunta.pergunta.replace(/\s+/g, '-').replace(/\?/g, '') : '')}`}
                  className="pergunta link-card"
                  onClick={() => alternarVideo(pergunta.id)}
                  style={{ '--bar-color': barColor }}
                >
                  {pergunta.pergunta}
                </a>
              </p>
            ))}
          </div>
        );
      };

      return (
        <div>
          <h3 id="duvidas-cvm-175">Navegue pelo seu perfil</h3>

          <p>Obtenha respostas claras e precisas sobre a Resolução CVM 175. Explore nossas perguntas frequentes, assista aos vídeos explicativos e mantenha-se atualizado com as últimas informações da nova regulamentação de fundos de investimento.</p>

          <div className="accordion">
            <div className={`accordion-item ${activeAccordion === 'Investidores' ? 'active' : ''}`}>
              <button className="button-investidores" onClick={() => handleAccordion('Investidores')}>Investidores</button>
              {activeAccordion === 'Investidores' && (
                <div className="accordion-content">
                  <div className="side-by-side">
                    <div className="left-content">
                      <h3>Perguntas</h3>
                      <div className="scrollable-container" style={{ overflowY: 'scroll', maxHeight: '350px' }}>
                        {records.map(record => (
                          <p key={record.id}>
                            <a
                              href={`#${encodeURIComponent(record.pergunta ? record.pergunta.replace(/\s+/g, '-').replace(/\?/g, '') : '')}`}
                              className="pergunta link-card"
                              onClick={() => alternarVideo(record.id)}
                              style={{ '--bar-color': barColor }}
                            >
                              {record.pergunta}
                            </a>
                          </p>
                        ))}
                      </div>
                    </div>
                    <div className="right-content">
                      <div className="card">
                        <div className="video-container">
                          {videoExibido ? (
                            <iframe
                              src={`https://www.youtube.com/embed/${videoExibido}&autoplay=1`}
                              title="Vídeo"
                              frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                              onLoad={() => incrementarAcesso(videoExibido)}
                            ></iframe>
                          ) : (
                            <div onClick={() => alternarVideo('canaldeajuda')} className="pergunta link-card">
                              <img className="pergunta link-card-img" src="/bnn-invesitdor.png" alt="Reprodução dos vídeos no youtube" />
                              {renderizarPerguntasMaisFrequentes()}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className={`accordion-item ${activeAccordion === 'Distribuidores' ? 'active' : ''}`}>
              <button className="button-distribuidores" onClick={() => handleAccordion('Distribuidores')}>Distribuidores</button>
              {activeAccordion === 'Distribuidores' && (
                <div className="accordion-content">
                  <div className="side-by-side">
                    <div className="left-content">
                      <h3>Perguntas</h3>
                      <div className="scrollable-container" style={{ overflowY: 'scroll', maxHeight: '350px' }}>
                        {records.map(record => (
                          <p key={record.id}>
                            <a
                              href={`#${encodeURIComponent(record.pergunta ? record.pergunta.replace(/\s+/g, '-').replace(/\?/g, '') : '')}`}
                              className="pergunta link-card"
                              onClick={() => alternarVideo(record.id)}
                              style={{ '--bar-color': barColor }}
                            >
                              {record.pergunta}
                            </a>
                          </p>
                        ))}
                      </div>
                    </div>
                    <div className="right-content">
                      <div  className="card">
                        <div className="video-container">
                          {videoExibido ? (
                            <iframe
                              src={`https://www.youtube.com/embed/${videoExibido}&autoplay=1`}
                              title="Vídeo"
                              frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                              onLoad={() => incrementarAcesso(videoExibido)}
                            ></iframe>
                          ) : (
                            <div onClick={() => alternarVideo('canaldeajuda')} className="pergunta link-card">
                              <img className="pergunta link-card-img" src="/bnn-adm-gestor.png" alt="Reprodução dos vídeos no youtube" />
                              {renderizarPerguntasMaisFrequentes()}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className={`accordion-item ${activeAccordion === 'Administradores e Gestores' ? 'active' : ''}`}>
              <button className="button-administradores" onClick={() => handleAccordion('Administradores e Gestores')}>Administradores e Gestores</button>
              {activeAccordion === 'Administradores e Gestores' && (
                <div className="accordion-content">
                  <div className="side-by-side">
                    <div className="left-content">
                      <h3>Perguntas</h3>
                      <div className="scrollable-container" style={{ overflowY: 'scroll', maxHeight: '350px' }}>
                        {records.map(record => (
                          <p key={record.id}>
                            <a
                              href={`#${encodeURIComponent(record.pergunta ? record.pergunta.replace(/\s+/g, '-').replace(/\?/g, '') : '')}`}
                              className="pergunta link-card"
                              onClick={() => alternarVideo(record.id)}
                              style={{ '--bar-color': barColor }}
                            >
                              {record.pergunta}
                            </a>
                          </p>
                        ))}
                      </div>
                    </div>
                    <div className="right-content">
                      <div  className="card">
                        <div className="video-container">
                          {videoExibido ? (
                            <iframe
                              src={`https://www.youtube.com/embed/${videoExibido}&autoplay=1`}
                              title="Vídeo"
                              frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                              onLoad={() => incrementarAcesso(videoExibido)}
                            ></iframe>
                          ) : (
                            <div onClick={() => alternarVideo('canaldeajuda')} className="pergunta link-card">
                              <img className="pergunta link-card-img" src="/bnn-adm-gestor.png" alt="Reprodução dos vídeos no youtube" />
                              {renderizarPerguntasMaisFrequentes()}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    };

    export default DuvidasFrequentes;
