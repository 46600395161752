import React, { useState, useEffect } from 'react';

function BannerParceiro() {
  const [indiceImagem, setIndiceImagem] = useState(0);
  const imagens = [
    '/banner-vedo01.png',
    '/banner-vedo02.png',
    '/banner-vedo03.png',
    '/banner-vedo04.png',
    '/banner-vedo05.png',
    '/banner-vedo06.png',
    '/banner-vedo07.png',
    '/banner-vedo08.png',
    '/banner-vedo09.png',
    '/banner-vedo10.png'
  ];

  useEffect(() => {
    const intervalId = setInterval(() => {
      const novoIndice = Math.floor(Math.random() * imagens.length);
      setIndiceImagem(novoIndice);
    }, 3000); // Alterado para 5000 milissegundos (5 segundos)

    return () => clearInterval(intervalId);
  }, [imagens]);

  return (
    <div className="banner-parceiro">
      <a
        href="https://www.vedo.com.br/mercadofinanceiro"
        target="_blank"
        rel="noopener noreferrer"
        className="Logo-link"
        alt="Vedo Domínios Web"
        title="Vedo Domínios Premium"
      >
        <img
          src={imagens[indiceImagem]}
          alt="Banner Vedo"
          width="80%"
        />
      </a>
    </div>
  );
}

export default BannerParceiro;
